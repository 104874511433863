const { REACT_APP_CONTENT_SERVICE_URL, REACT_APP_DEFAULT_FLOW, REACT_APP_DEFAULT_OFFER } = process.env;

export const getURLParam = (name, url) => {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[[]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const loadURL = (url) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = url;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    });
};

export const prepareImageUrl = (storeType, src, pattern) => {
    if (storeType === 'magento') {
        return src.replace(/^http:/, '');
    }
    if (storeType === 'shopify') {
        return src.replace(
            /(\.jpg(?=[^.jpg]*$)|\.png(?=[^.png]*$)|\.gif(?=[^.gif]*$)|\.jpeg(?=[^.jpeg]*$))/,
            pattern || '_180x$1'
        );
    }

    return src;
};

export const parseURL = (url) => {
    if (!url) {
        return '';
    }

    if (window.URL) {
        return new URL(url).host;
    }
    const match = url.match(/^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    return match && match[2];
};

export const timeElapsed = () => {
    const now = new Date().getTime();
    return ((now - performance.timing.navigationStart) / 1000).toFixed(1);
};

export const roundToLocaleString = (value, maxDigits = 2) => {
    const toLocaleStringOptions = {
        minimumFractionDigits: maxDigits,
        maximumFractionDigits: maxDigits
    };

    return Number(value).toLocaleString('en-US', toLocaleStringOptions).replace(/,$/, '.');
};

export const appendBasisExcludeScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
        var ssaUrl = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'pixel-a.basis.net/iap/d7da8da79cfd2b91';new Image().src = ssaUrl; (function(d) { var syncUrl = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'pixel-a.basis.net/dmp/asyncPixelSync'; var iframe = d.createElement('iframe'); (iframe.frameElement || iframe).style.cssText = "width: 0; height: 0; border: 0;"; iframe.src = "javascript:false"; d.body.appendChild(iframe); var doc = iframe.contentWindow.document; doc.open().write('<body onload="window.location.href=\\''+syncUrl+'\\'">'); doc.close(); })(document);
    `;
    document.head.appendChild(script);
};

export const calculateDiscountValue = (price, originalPrice) => {
    const fPrice = parseFloat(price);
    const fOriginalPrice = parseFloat(originalPrice);
    if (fOriginalPrice && fOriginalPrice > fPrice) {
        return `(${Math.round((1 - fPrice / fOriginalPrice) * 100)}% OFF)`;
    }
};

export const generateCheckoutLink = (promotionId) => {
    const utmString = '&utm_source=Klickly&utm_medium=Marketing&utm_campaign=General&utm_term=General';
    const trackingString = '&conversionChannel=checkout-widget';
    return `${REACT_APP_CONTENT_SERVICE_URL}/offer/${REACT_APP_DEFAULT_OFFER}/${REACT_APP_DEFAULT_FLOW}?promotions=${promotionId}${utmString}${trackingString}`;
};
